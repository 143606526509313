<template>
  <div class="sub-container">
    <div class="content">
      <h2 class="page-title">정산관리</h2>

      <div class="table-search">
        <div class="ts-sch-col">
          <input
                  type="text"
                  class="ts-sch-col__inp inp-txt-common"
                  placeholder="이름, 휴대폰번호, 병원명 검색"
                  v-model="keyword"
                  @keyup.enter="list()"
          />
          <button class="ts-sch-col__btn">
            <span class="is-blind">검색하기</span>
          </button>
        </div>
        <div class="ts-date-picker">
          <input
                  type="date"
                  class="ts-date-picker__inp inp-txt-common from"
                  placeholder="부터"
                  v-model="start"
          />
          <input
                  type="date"
                  class="ts-date-picker__inp inp-txt-common to"
                  placeholder="까지"
                  v-model="end"
          />
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.insureType" @change="list">
            <option value="">보험유형</option>
            <option value="직장 건강보험가입자">직장 건강보험가입자</option>
            <option value="지역 건강보험가입자">지역 건강보험가입자</option>
          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.applicationType" @change="list">
            <option value="">신청항목</option>
            <option value="의료비지원">의료비지원</option>
            <option value="재외동포지원">재외동포지원</option>
          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.status" @change="list">
            <option value="">진행상황</option>
            <option value="received">접수</option>
            <option value="selected">선정</option>
            <option value="recheck">재확인</option>
            <option value="review">심의</option>
            <option value="drop">탈락</option>
            <option value="cancel">신청포기</option>
            <option value="treatment_progress">치료진행</option>
            <option value="treatment_completed">치료완료</option>


          </select>
        </div>
        <div class="ts-select select-wrap">
          <select v-model="param.review" @change="list">
            <option value="">심의여부</option>
            <option value="true">심의</option>
            <option value="false">비심의</option>
          </select>
        </div>
        <div class="ts-button">
          <button class="btn-search" @click="list()">검색하기</button>
        </div>
      </div>

      <div class="table-util">
        <div class="util-start">

          <button class="btn-down-excel" @click="downloadExcel">
            <span class="txt">엑셀다운로드</span>
          </button>
          <span class="us-txt">선택항목의 상태변경</span>
          <div class="tus-select select-wrap">
            <select v-model="applicationStatus" @change="changeStatusApplications">
              <option value="" >선택</option>
              <option value="settlement_waiting">정산대기</option>
            </select>
          </div>
          <span class="us-txt">검색결과: 전체 {{total}}건 중 {{applicationData.length}}건</span>
                    <span class="us-txt">결과내 지급총액: {{totalPrice}}원</span>
        </div>
        <div class="util-end">
          <div class="tue-select select-wrap">
            <select v-model="param.pageSize" @change="list">
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
              <option value="30">30개씩 보기</option>
              <option value="50">50개씩 보기</option>
            </select>
          </div>
        </div>
      </div>

      <table class="tbl-list-default application-mng">
        <colgroup>
          <col class="col01" />
          <col class="col02" />
          <col class="col03" />
          <col class="col04" />
          <col class="col05" />
          <col class="col06" />
          <col class="col07" />
          <col class="col08" />
          <col class="col09" />
          <col class="col10" />
          <col class="col11" />
          <col class="col12" />
          <col class="col13" />
          <col class="col14" />
          <col class="col02" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input type="checkbox" id="chkAll" class="checkbox" />
              <label for="chkAll" class="is-blind">전체선택</label>
            </th>
            <th>접수일</th>
            <th>치료시작일</th>
            <th>이름</th>
            <th>법정생년월일</th>
            <th>내외국인/성별</th>
            <th>지역</th>
            <th>휴대폰번호</th>
            <th>보험유형</th>
            <th>진행상태</th>
            <th>정산금액</th>
            <th>지급일</th>
            <th>병원</th>
            <th>비고</th>
            <th>정산서류</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="application in applicationData"
            v-bind:key="application.applicationId"
            style="cursor: pointer"
          >
            <td>
              <input type="checkbox" id="chk01" class="checkbox" />
              <label for="chk01" class="is-blind">선택</label>
            </td>
            <td @click="openApplication(application.applicationId)">
              {{ application.createdAt.substring(0, 16).replace("T", " ") }}
            </td>
            <td @click="openApplication(application.applicationId)"></td>
            <td @click="openApplication(application.applicationId)">{{ application.name }}</td>
            <td @click="openApplication(application.applicationId)">{{ application.socialSecurityNumber.substring(0, 6) }}</td>
            <td @click="openApplication(application.applicationId)">{{ application.nationality }}</td>
            <td @click="openApplication(application.applicationId)">{{ application.area }}</td>
            <td @click="openApplication(application.applicationId)">{{ application.mobile }}</td>
            <td @click="openApplication(application.applicationId)">{{ application.healthInsuranceType }}</td>
            <td>
              <div class="select-wrap condition">
                {{statusMap[application.status]}}
              </div>
            </td>
            <td @click="openApplication(application.applicationId)">{{application.settlementAmount}} 원</td>
            <td @click="openApplication(application.applicationId)">2021-05-10</td>
            <td @click="openApplication(application.applicationId)">
              <span v-if="application.hospital != undefined">{{
                application.hospital.hospitalName
              }}<br/>{{application.hospital.tel}}</span>
            </td>
            <td @click="openApplication(application.applicationId)">{{ application.medicalMemo }}</td>
            <td>
              <button class="btn-memo">
                <span class="is-blind">정산서류</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="table-foot clearfix">
        <Pagination location="applications" ref="pagination" :page="nowPage" :page-size="param.pageSize" ></Pagination>


      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import Pagination from "../components/Pagination";
import Xlsx from "xlsx";
export default {
  name: "Calculate",
  components: { Pagination },
  data: () => ({
    keyword: null,
    start: null,
    end: null,
    insureType: null,
    applicationType: null,
    applicationStatus: "",
    subject: null,
    status: null,
    review: null,
    nowPage: 1,
    total: 0,
    totalPrice: 0,
    param: {
      page: 0,
      pageSize: 10,
      start: null,
      end: null,
      keyword: null,
      insureType: "",
      applicationType: "",
      status: "",
      review: "",
    },
    statusMap: {
      received:"접수",
      selected:"선정",
      recheck:"재확인",
      review:"심의",
      first_examination:"초진완료",
      treatment_progress:"치료진행",
      examination_completed:"검진완료",
      treatment_completed:"치료완료",
      settlement_waiting:"정산대기",
      settlement_completed:"정산완료",
      drop:"탈락",
      timeout:"기간만료",
      cancel:"취소",
    },
    applicationData: []
  }),
  mounted() {
    console.log(this.$route.path);
  },
  beforeMount() {
    if (this.$route.query.page != "" && this.$route.query.page != null) {
      this.nowPage = this.$route.query.page;
    } else {
      this.nowPage = 1;
    }

    if (this.$route.query.pageSize == undefined) {
      this.param.pageSize = 10;
    } else {
      this.param.pageSize = this.$route.query.pageSize;
    }

    this.list();
  },
  methods: {
    openApplication(applicationId) {
      this.$router.push({ path: "calculate/" + applicationId });

      console.log(applicationId);
    },
    newApplication() {
      this.$router.push({ path: "application/new" });
    },
    requestList() {
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;

      return this.$axios.get("settlement-applications", { params: this.param });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.applicationData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.param.pageSize) {
        this.selectedArray = [];
      } else {
        this.selectedArray = [];
        for(var i=0;i<this.data.length;i++) {

          this.selectedArray.push(this.data[i].applicationId) ;
        }
      }
    },
    changeStatus(applicationId,status){
      if (confirm("선택한 신청서 상태를 변경하시겠습니까?")) {
        var param = {
          applicationId: applicationId,
          status: status,
          contents: "상태변경"
        };
        this.$axios
                .post("application/status", param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.list();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
      console.log(applicationId,status);
    },
    changeStatusApplications(){

      if (confirm("선택한 신청서들의 상태를 변경하시겠습니까?")) {

        var ids = this.selectedArray.join(",");

        var param = {
          ids: ids,
          status: this.applicationStatus,
          contents: "상태변경"
        };
        this.$axios
                .post("application/status-group", param)
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.list();
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
      console.log(this.applicationStatus);
    },
    downloadExcel() {
      const workBook = Xlsx.utils.book_new()
      const workSheet = Xlsx.utils.json_to_sheet(this.applicationData)
      Xlsx.utils.book_append_sheet(workBook, workSheet, '신청서')
      Xlsx.writeFile(workBook, '정산리스트.xlsx')
    }
  }
}
</script>
